// store.js
import { configureStore } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'

const initialState = {
  sidebarShow: true,
  autenticated: localStorage.getItem('autenticated'),
  token: localStorage.getItem('token'),
  username: localStorage.getItem('username'),
  admin: localStorage.getItem('admin'),
  asideShow: false,
  theme: 'default',
}

const changeState = (state = initialState, action) => {
  const { type, ...rest } = action
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

const store = configureStore({
  reducer: changeState,
})

export default store
